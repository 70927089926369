<template>
  <div class="common-layout">
    <el-container>
      <el-main class="vh-100 d-flex justify-content-center align-items-center">
        <el-row :gutter="24" :justify="'center'" class="w-100">
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6" class="m-0 p-0 align-content-center align-items-center">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <h3 class="h3 mb-3">circles.mirror</h3>
              <div class="w-100 mb-3">
                <el-input
                    v-model="email"
                    placeholder="Email"
                    class="w-100"
                />
              </div>
              <div class="w-100 mb-3">
                <el-input
                    v-model="password"
                    type="password"
                    placeholder="Пароль"
                    show-password
                    class="w-100"
                />
              </div>
             <el-button type="primary" class="w-100" @click="loginUser">Вход</el-button>
            </div>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>


<script>
import { ElMessage } from 'element-plus'
import axios from 'axios'
import utils from '@/utils'

export default {
  name: 'MainView',
  components: {},
  props: {},
  computed: {},
  data() {
    return {
      email: '',
      password: '',
    }
  },
  methods: {
    loginUser() {
      axios({
        method: 'POST',
        headers: {'X-Auth': this.email},
        url: utils.API + `users/login`,
        // withCredentials: true,
        data: {email: this.email, password: this.password},
      }).then(() => {
        this.$cookies.set('email', this.email, '30d')
        this.$router.push('/')
      }).catch(error => {
        ElMessage({
          message: error.response.data ? error.response.data.detail : error,
          type: 'error',
        })
      })
    },
  },
  mounted() {

  }
}
</script>


<style scoped>
.renew-button {
  background-color: rgb(54, 54, 54) !important;
  color: white !important;
}

</style>
