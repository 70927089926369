import { createRouter, createWebHistory } from 'vue-router'
import MainView from "@/views/MainView.vue"
import LoginView from "@/views/LoginView.vue"

const routes = [
  {
    path: '/',
    name: 'MainView',
    component: MainView,
    props: {msg: 'KEK CHEBUREK'},
    meta: {title: 'Circles Mirror'}
  },
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView,
    meta: {title: 'Circles Mirror'}
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
