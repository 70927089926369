import { createStore } from 'vuex'
import devices from '@/store/modules/devices'


const store = createStore({
  modules: {
    devices: devices,
  }
})

export default store
