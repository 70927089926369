<template>
  <router-view/>
</template>


<style>
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url(@/assets/fonts/Montserrat-Regular.ttf) format("truetype");
}
#app {
  font-family: Montserrat, sans-serif;
  color: white;
  min-height: 100%;
}

html {
  min-height: calc(100vh - 0px) !important;
  background-color: #252525 !important;
  color: white;
}

body {
  min-height: calc(100vh - 0px) !important;
  color: white;
  padding: 0;
  margin: 0 auto;
  background-color: #252525 !important;
}
</style>
