<template>
  <p class="m-0 p-0 me-3" style="cursor: pointer" @click="showNotesDialog = true">
    <el-icon>
      <Setting/>
    </el-icon>
  </p>

  <el-dialog
      v-model="showNotesDialog"
      title="Быстрая заметка"
      align-center
      destroy-on-close
      fullscreen
      style="background-color: #252525;"
  >
    <p class="m-0 p-0 mb-3" v-if="currentText">
      Текущий текст заметки: "{{ currentText }}"
    </p>
    <p class="m-0 p-0 mb-3" v-else>
      Текущий текст заметки не установлен
    </p>
    <el-input
        class="mb-3"
        v-model="text"
        :rows="6"
        type="textarea"
        maxlength="200"
        show-word-limit
        placeholder="Введите новый текст"
    />
    <div class="d-flex justify-content-end">
      <el-button type="primary" @click="submitNote">Сохранить</el-button>
    </div>
  </el-dialog>


</template>

<script>
import { ElMessage } from 'element-plus'
import axios from 'axios'
import utils from "@/utils";

export default {
  name: 'NotesWidget',
  props: {
    device: Object,
  },
  data() {
    return {
      showNotesDialog: false,
      currentText: '',
      text: ''
    }
  },
  methods: {
    submitNote() {
      let payload = {name: this.device.name}
      let widgets = []
      for (let w of this.device.widgets) {
        if (w.name !== 'notes') {
          widgets.push(w)
        } else {
          w.data = {}
          w.data.text = this.text
          widgets.push(w)
        }
      }
      payload['widgets'] = widgets
      axios({
        method: 'PATCH',
        // headers: {'Authorization': this.$cookies.get('email')},
        url: utils.API + `devices/${this.device.id}`,
        // withCredentials: true,
        data: payload,
        headers: {'X-Auth': utils.getEmail()},
      }).then(() => {
        this.currentText = this.text
        this.text = ''
        ElMessage({
          message: 'Новая заметка будет установлена в течение пяти минут',
          type: 'success'
        })
      }).catch(error => {
        ElMessage({
          message: error,
          type: 'error',
        })
      })
      this.showNotesDialog = false
    }
  },
  mounted() {
    for (let widget of this.device.widgets) {
      if (widget.name === 'notes') {
        this.currentText = widget.data.text
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.lol-kek {
  color: white;
}

@media only screen and (min-width: 1920px)
.lol-kek {
    display: block;
    max-width: 25%;
    flex: 0 0 25%;
}

@media only screen and (min-width: 1200px)
.lol-kek {
    display: block;
    max-width: 33.3333333333%;
    flex: 0 0 33.3333333333%;
}

@media only screen and (min-width: 992px)
.lol-kek {
    display: block;
    max-width: 50%;
    flex: 0 0 50%;
}

@media only screen and (min-width: 768px)
.lol-kek {
    display: block;
    max-width: 100%;
    flex: 0 0 100%;
}
</style>
