<template>
  <p class="m-0 p-0 me-3" style="cursor: pointer" @click="showIFrameDialog = true">
    <el-icon>
      <Setting/>
    </el-icon>
  </p>

  <el-dialog
      v-model="showIFrameDialog"
      title="Внешний источник"
      align-center
      destroy-on-close
      fullscreen
      style="background-color: #252525;"
      :before-close="closeIFrameSettings"
  >
    <div class="m-0 p-0 mb-3">
      <p class="m-0 p-0">Ширина (px):</p>
      <el-input-number
          v-model="newDisplaySettings.width"
          size="small"
          :min="1"
          :max="3840"
      />

    </div>
    <div class="m-0 p-0 mb-3">
      <p class="m-0 p-0">Высота (px):</p>
      <el-input-number
          v-model="newDisplaySettings.height"
          size="small"
          :min="1"
          :max="3840"
      />
    </div>
        <div class="m-0 p-0 mb-3">
      <p class="m-0 p-0">Наложение (px):</p>
      <el-input-number
          v-model="newDisplaySettings.overlay"
          size="small"
          :min="1"
          :max="100"
      />
    </div>
        <div class="m-0 p-0 mb-3">
      <p class="m-0 p-0 ">Адрес:</p>
      <el-input
          v-model="newDisplaySettings.url"
          size="small"
          :min="1"
          :max="300"
          style="max-width: 380px"
      />
    </div>
    <div class="d-flex justify-content-end">
      <el-button type="primary" @click="submitIFrameSettings">Сохранить</el-button>
    </div>
  </el-dialog>


</template>

<script>
import { ElMessage } from 'element-plus'
import axios from 'axios'
import utils from '@/utils'

export default {
  name: 'IFrameWidget',
  props: {
    device: Object,
  },
  data() {
    return {
      showIFrameDialog: false,
      currentDisplaySettings: {width: 0, height: 0, url: '', overlay: 0},
      newDisplaySettings: {width: 0, height: 0, url: '', overlay: 0}
    }
  },
  methods: {
    closeIFrameSettings() {
      this.newDisplaySettings.height = this.currentDisplaySettings.height
      this.newDisplaySettings.width = this.currentDisplaySettings.width
      this.newDisplaySettings.url = this.currentDisplaySettings.url
      this.newDisplaySettings.overlay = this.currentDisplaySettings.overlay
      this.showIFrameDialog = false
    },
    submitIFrameSettings() {
      let payload = {name: this.device.name}
      let widgets = []
      for (let w of this.device.widgets) {
        if (w.name !== 'iframe') {
          widgets.push(w)
        } else {
          w.data = {}
          w.data.height = this.newDisplaySettings.height
          w.data.width = this.newDisplaySettings.width
          w.data.url = this.newDisplaySettings.url
          w.data.overlay = this.newDisplaySettings.overlay
          widgets.push(w)
        }
      }
      payload['widgets'] = widgets
      axios({
        method: 'PATCH',
        // headers: {'Authorization': this.$cookies.get('email')},
        url: utils.API + `devices/${this.device.id}`,
        // withCredentials: true,
        data: payload,
        headers: {'X-Auth': utils.getEmail()},
      }).then(() => {
        this.currentDisplaySettings.height = this.newDisplaySettings.height
        this.currentDisplaySettings.width = this.newDisplaySettings.width
        this.currentDisplaySettings.url = this.newDisplaySettings.url
        this.currentDisplaySettings.overlay = this.newDisplaySettings.overlay
        ElMessage({
          message: 'Новая конфигурация будет установлена в течение пяти минут',
          type: 'success'
        })
      }).catch(error => {
        ElMessage({
          message: error,
          type: 'error',
        })
      })
      this.showIFrameDialog = false
    }
  },
  mounted() {
    for (let widget of this.device.widgets) {
      if (widget.name === 'iframe') {
        this.currentDisplaySettings = {height: widget.data.height, width: widget.data.width, url: widget.data.url || '', overlay: widget.data.overlay}
        this.newDisplaySettings = {height: widget.data.height, width: widget.data.width, url: widget.data.url || '', overlay: widget.data.overlay}
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.lol-kek {
  color: white;
}

@media only screen and (min-width: 1920px)
.lol-kek {
    display: block;
    max-width: 25%;
    flex: 0 0 25%;
}

@media only screen and (min-width: 1200px)
.lol-kek {
    display: block;
    max-width: 33.3333333333%;
    flex: 0 0 33.3333333333%;
}

@media only screen and (min-width: 992px)
.lol-kek {
    display: block;
    max-width: 50%;
    flex: 0 0 50%;
}

@media only screen and (min-width: 768px)
.lol-kek {
    display: block;
    max-width: 100%;
    flex: 0 0 100%;
}
</style>
