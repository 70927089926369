<template>
  <p class="m-0 p-0" style="cursor: pointer" @click="showCityDialog = true">
    <el-icon style="font-size: 1.15rem" class="mt-1 ms-2">
      <Edit/>
    </el-icon>
  </p>

  <el-dialog
      v-model="showCityDialog"
      title="Ваш город"
      align-center
      destroy-on-close
      fullscreen
      style="background-color: #252525 !important;"
  >
    <p>
      Если вы испытываете проблемы при получении информации о погоде и пробках, или она просто не совсем соответствует
      действительности, можно подсказать зеркалу ваше настоящее местоположение. Эта функция также полезна, если внутри
      сети используется VPN, точка выхода в сеть Интернет которого отличается от города, в котором вы фактически
      находитесь.
      <br/><br/>
    </p>

    <div class="el-col-xs-24 col-sm-24 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
      <el-select v-model="newLocation" class="mb-3 w-100" placeholder="Выберите местоположение" size="large">
        <el-option
            v-for="(item, index) in citiesList"
            :key="index='citiesList_ar_ra_y'"
            :label="item === 'not_defined' ? 'Авто' : item"
            :value="item"
        />
      </el-select>
    </div>

    <div class="d-flex justify-content-end">
      <el-button @click="submitNewLocation" type="primary">Сохранить</el-button>
    </div>
  </el-dialog>


</template>

<script>
import {ElMessage} from 'element-plus'
import axios from 'axios'
import utils from "@/utils";

export default {
  name: 'ChangeDeviceLocation',
  props: {
    device: Object,
  },
  data() {
    return {
      showCityDialog: false,
      newLocation: this.device.city !== 'not_defined' ? this.device.city : 'Авто',
      citiesList: [],
    }
  },
  methods: {
    submitNewLocation() {
      let payload = {city: this.newLocation}
      axios({
        method: 'PATCH',
        // headers: {'Authorization': this.$cookies.get('email')},
        url: utils.API + `devices/${this.device.id}`,
        // withCredentials: true,
        data: payload,
        headers: {'X-Auth': utils.getEmail()},
      }).then(() => {
        ElMessage({
          message: 'Новая конфигурация будет установлена в течение пяти минут',
          type: 'success',
        })
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      }).catch(error => {
        ElMessage({
          message: error.response.data.detail,
          type: 'error',
        })
      })
    }
  },
  mounted() {
    axios({
      method: 'GET',
      // headers: {'Authorization': this.$cookies.get('email')},
      url: utils.API + `info/cities`,
      // withCredentials: true,
      headers: {'X-Auth': utils.getEmail()},
    }).then(response => {
      this.citiesList = response.data
    }).catch(error => {
      ElMessage({
        message: error.response.data.detail,
        type: 'error',
      })
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@media only screen and (min-width: 1920px)

.lol-kek {
  display: block;
  max-width: 25%;
  flex: 0 0 25%;
}

@media only screen and (min-width: 1200px)

.lol-kek {
  display: block;
  max-width: 33.3333333333%;
  flex: 0 0 33.3333333333%;
}

@media only screen and (min-width: 992px)

.lol-kek {
  display: block;
  max-width: 50%;
  flex: 0 0 50%;
}

@media only screen and (min-width: 768px)

.lol-kek {
  display: block;
  max-width: 100%;
  flex: 0 0 100%;
}
</style>
