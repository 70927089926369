<template>
  <p class="m-0 p-0" style="cursor: pointer" @click="showNameDialog = true">
    <el-icon style="font-size: 1.15rem" class="mt-1 ms-2">
      <Edit/>
    </el-icon>
  </p>

  <el-dialog
      v-model="showNameDialog"
      title="Имя устройства"
      align-center
      destroy-on-close
      fullscreen
      style="background-color: #252525 !important;"
  >
    <el-input
        class="mb-3"
        v-model="deviceNewName"
        :rows="2"
        type="textarea"
        maxlength="30"
        show-word-limit
        placeholder="Введите новое имя устройства"
    />
    <div class="d-flex justify-content-end">
<!--      <el-button type="info" @click="showNameDialog = false">Отмена</el-button>-->
      <el-button @click="submitNewName" type="primary">Сохранить</el-button>
    </div>
  </el-dialog>


</template>

<script>
import { ElMessage } from 'element-plus'
import axios from 'axios'
import utils from "@/utils";

export default {
  name: 'ChangeDeviceName',
  props: {
    device: Object,
  },
  data() {
    return {
      showNameDialog: false,
      deviceNewName: ''
    }
  },
  methods: {
    submitNewName() {
      let payload = {name: this.deviceNewName}
      axios({
        method: 'PATCH',
        // headers: {'Authorization': this.$cookies.get('email')},
        url: utils.API + `devices/${this.device.id}`,
        // withCredentials: true,
        data: payload,
        headers: {'X-Auth': utils.getEmail()},
      }).then(() => {
        ElMessage({
          message: 'Новое имя установлено',
          type: 'success',
        })
        this.deviceNewName = ''
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      }).catch(error => {
        ElMessage({
          message: error.response.data.detail,
          type: 'error',
        })
      })
      this.showNotesDialog = false
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@media only screen and (min-width: 1920px)
.lol-kek {
    display: block;
    max-width: 25%;
    flex: 0 0 25%;
}

@media only screen and (min-width: 1200px)
.lol-kek {
    display: block;
    max-width: 33.3333333333%;
    flex: 0 0 33.3333333333%;
}

@media only screen and (min-width: 992px)
.lol-kek {
    display: block;
    max-width: 50%;
    flex: 0 0 50%;
}

@media only screen and (min-width: 768px)
.lol-kek {
    display: block;
    max-width: 100%;
    flex: 0 0 100%;
}
</style>
