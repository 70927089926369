import axios from 'axios'
import utils from '@/utils'
import {ElMessage} from 'element-plus'

export default {
  namespaced: true,
  state: () => ({
    devicesIDs: [],
    devices: [],
  }),
  actions: {
    async fetchDevices(ctx) {
      ctx.state.devices = []
      const userEmail = utils.getEmail()
      axios({
        method: 'GET',
        headers: {'X-Auth': userEmail},
        url: utils.API + `users?email=${userEmail}`
      }).then(response => {
        ctx.commit('setDevicesIDs', response.data.devices)
        for (let deviceID of response.data.devices) {
          axios({
            method: 'GET',
            headers: {'X-Auth': userEmail},
            url: utils.API + `devices/${deviceID}`
          }).then(response => {
            ctx.commit('addDevice', response.data)
          }).catch((error) => {
            ElMessage({
              message: error.response.data ? error.response.data.detail : error,
              type: 'error',
            })
          })
        }
      }).catch(() => {
      })
    }
  },
  mutations: {
    addDevice(state, devicePayload) {
      state.devices.push(devicePayload)
    },
    setDevicesIDs(state, array) {
      state.devicesIDs = array
    },
  },
  getters: {
    getDevices(state) {
      state.devices.sort((a, b) => {a.name - b.name})
      return state.devices
    },
    getDeviceByID: state => ID => {
      for (const dev of state.devices) {
        if (dev.id === ID) {
          return dev
        }
      }
    }
  }
}
