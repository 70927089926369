<template>
  <p class="m-0 p-0 me-3" style="cursor: pointer" @click="showGalleryDialog = true">
    <el-icon>
      <Setting/>
    </el-icon>
  </p>

  <el-dialog
      v-model="showGalleryDialog"
      title="Настройка галереи"
      align-center
      destroy-on-close
      fullscreen
      style="background-color: #252525;"
      :on-close="submitFile"
  >

    <div class="w-100">
      <div class="m-0 p-0 mb-3">
        <p class="m-0 p-0">Ширина (px):</p>
        <el-input-number
            v-model="width"
            size="small"
            :min="1"
            :max="900"
        />

      </div>
      <div class="m-0 p-0 mb-3">
        <p class="m-0 p-0">Высота (px):</p>
        <el-input-number
            v-model="height"
            size="small"
            :min="1"
            :max="1400"
        />
      </div>
      <p>Галерея включает не более 10 изображений, размер каждого должен находиться в пределах от 1 до 5Mb</p>
      <el-upload
          v-model:file-list="pics"
          :action="`https://circles-mirror.ru/api/v1/files?device_id=${device.id}&direction=gallery`"
          :headers="{'x-auth': this.$cookies.get('email').replace('%40', '@')}"
          list-type="picture-card"
          :disabled="pics.length > 10"
          :on-remove="removePicture"
      >
        <el-icon>
          <Plus/>
        </el-icon>
      </el-upload>
    </div>

    <div class="d-flex justify-content-end">
      <el-button type="primary" @click="submitFile">Сохранить</el-button>
    </div>
  </el-dialog>


</template>

<script>
import axios from "axios";
import utils from "@/utils";
import {ElMessage} from "element-plus";
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'GalleryWidget',
  props: {
    device: Object,
  },
  computed: {
    ...mapGetters({
      devices: "devices/getDevices",
      getDeviceByID: 'devices/getDeviceByID'
    }),
  },
  data() {
    return {
      showGalleryDialog: false,
      height: 400,
      width: 400,
      pics: [],
    }
  },
  methods: {
    ...mapActions(['devices/fetchDevices']),
    removePicture(pic, remainedPics) {
      axios({
        method: 'DELETE',
        headers: {'X-Auth': utils.getEmail()},
        url: utils.API + `files/${pic.name}`,
      }).then(() => {
      }).catch(error => {
        ElMessage({
          message: error.response.data ? error.response.data.detail : error,
          type: 'error',
        })
      })

      let newWidgetsData = []
      for (let widget of this.getDeviceByID(this.device.id).widgets) {
        if (widget.name !== 'gallery') {
          newWidgetsData.push(widget)
        } else {
          let newPics = []
          for (let p of remainedPics) {
            newPics.push(p.name)
          }
          widget.data.pics = newPics
          newWidgetsData.push(widget)
        }
      }
      axios({
        method: 'PATCH',
        headers: {'X-Auth': utils.getEmail()},
        url: utils.API + `devices/${this.device.id}`,
        data: {widgets: newWidgetsData}
      }).then(() => {
      }).catch(error => {
        ElMessage({
          message: error.response.data ? error.response.data.detail : error,
          type: 'error',
        })
      })
    },
    submitFile() {
      this.$store.dispatch('devices/fetchDevices')
      for (let widget of this.device.widgets) {
        if (widget.name === 'gallery') {
          if (widget.data.height !== this.height || widget.data.width !== this.width) {
            let newWidgets = []
            for (let widget of this.device.widgets) {
              if (widget.name === 'gallery') {
                let changedWidget = {...widget}
                changedWidget.data.height = this.height
                changedWidget.data.width = this.width
                newWidgets.push(changedWidget)
              } else {
                newWidgets.push(widget)
              }
            }
            axios({
              method: 'PATCH',
              // headers: {'Authorization': this.$cookies.get('email')},
              url: utils.API + `devices/${this.device.id}`,
              // withCredentials: true,
              data: {widgets: newWidgets},
              headers: {'X-Auth': utils.getEmail()},
            }).then(() => {
              ElMessage({
                message: 'Новая конфигурация будет установлена в течение пяти минут',
                type: 'success'
              })
            }).catch(error => {
              ElMessage({
                message: error,
                type: 'error',
              })
            })
          }
        }
      }
      this.showGalleryDialog = false
    }
  },
  mounted() {
    for (let widget of this.device.widgets) {
      if (widget.name === 'gallery') {
        this.height = widget.data.height
        this.width = widget.data.width
        this.pics = []
        for (const filename of widget.data.pics) {
          this.pics.push({name: filename, url: `https://circles-mirror.ru/api/v1/files/${filename}`})
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  text-align: center;
}
</style>
