export default {
    API: 'https://circles-mirror.ru/api/v1/',
    getCookie: function (cookieName) {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${cookieName}=`)
        if (parts.length === 2) return parts.pop().split(';').shift() || false
  },
    getEmail: function () {
        let rawMail = this.getCookie('email')
        return rawMail.replace('%40', '@')
    }
}