<template>
  <p class="m-0 p-0 me-3" style="cursor: pointer" @click="showHoroscopesDialog = true">
    <el-icon>
      <Setting/>
    </el-icon>
  </p>

  <el-dialog
      v-model="showHoroscopesDialog"
      title="Гороскоп"
      align-center
      destroy-on-close
      fullscreen
      style="background-color: #252525;"
      :before-close="closeHoroscopeSettings"
  >
    <el-checkbox-group v-model="currentSigns">
      <div class="mb-2">
        <el-checkbox label="aries" border style="--el-checkbox-checked-text-color: linen !important;">Овен</el-checkbox>
        <el-checkbox label="taurus" border style="--el-checkbox-checked-text-color: linen !important;">Телец</el-checkbox>
        <el-checkbox label="gemini" border style="--el-checkbox-checked-text-color: linen !important;">Близнецы</el-checkbox>
      </div>
      <div class="mb-2">
        <el-checkbox label="cancer" border style="--el-checkbox-checked-text-color: linen !important;">Рак</el-checkbox>
        <el-checkbox label="leo" border style="--el-checkbox-checked-text-color: linen !important;">Лев</el-checkbox>
        <el-checkbox label="virgo" border style="--el-checkbox-checked-text-color: linen !important;">Дева</el-checkbox>
      </div>
      <div class="mb-2">
        <el-checkbox label="libra" border style="--el-checkbox-checked-text-color: linen !important;">Весы</el-checkbox>
        <el-checkbox label="scorpio" border style="--el-checkbox-checked-text-color: linen !important;">Скорпион</el-checkbox>
        <el-checkbox label="sagittarius" border style="--el-checkbox-checked-text-color: linen !important;">Стрелец</el-checkbox>
      </div>
      <div class="mb-2">
        <el-checkbox label="capricorn" border style="--el-checkbox-checked-text-color: linen !important;">Козерог</el-checkbox>
        <el-checkbox label="aquarius" border style="--el-checkbox-checked-text-color: linen !important;">Водолей</el-checkbox>
        <el-checkbox label="pisces" border style="--el-checkbox-checked-text-color: linen !important;">Рыбы</el-checkbox>
      </div>
    </el-checkbox-group>

    <div class="d-flex justify-content-end">
      <el-button type="primary" @click="submitHoroscopeSettings">Сохранить</el-button>
    </div>
  </el-dialog>


</template>

<script>
import {ElMessage} from 'element-plus'
import axios from 'axios'
import utils from '@/utils'

export default {
  name: 'HoroscopeWidget',
  props: {
    device: Object,
  },
  data() {
    return {
      showHoroscopesDialog: false,
      currentSigns: [],
    }
  },
  methods: {
    closeHoroscopeSettings() {
      this.showHoroscopesDialog = false
    },
    submitHoroscopeSettings() {
      let payload = {name: this.device.name}
      let widgets = []
      for (let w of this.device.widgets) {
        if (w.name !== 'horoscopes') {
          widgets.push(w)
        } else {
          w.data.signs = this.currentSigns
          widgets.push(w)
        }
      }
      payload['widgets'] = widgets
      axios({
        method: 'PATCH',
        // headers: {'Authorization': this.$cookies.get('email')},
        url: utils.API + `devices/${this.device.id}`,
        // withCredentials: true,
        data: payload,
        headers: {'X-Auth': utils.getEmail()},
      }).then(() => {
        ElMessage({
          message: 'Новая конфигурация будет установлена в течение пяти минут',
          type: 'success'
        })
      }).catch(error => {
        ElMessage({
          message: error,
          type: 'error',
        })
      })
      this.showHoroscopesDialog = false
    }
  },
  mounted() {
    for (let widget of this.device.widgets) {
      if (widget.name === 'horoscopes') {
        this.currentSigns = widget.data.signs
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media only screen and (min-width: 1920px)

.lol-kek {
  display: block;
  max-width: 25%;
  flex: 0 0 25%;
}

@media only screen and (min-width: 1200px)

.lol-kek {
  display: block;
  max-width: 33.3333333333%;
  flex: 0 0 33.3333333333%;
}

@media only screen and (min-width: 992px)

.lol-kek {
  display: block;
  max-width: 50%;
  flex: 0 0 50%;
}

@media only screen and (min-width: 768px)

.lol-kek {
  display: block;
  max-width: 100%;
  flex: 0 0 100%;
}

.el-checkbox {
  --el-checkbox-checked-text-color: #E5EAF3 !important;
}
.is-checked {
  color: white !important;
}
</style>
