<template>
  <div class="common-layout">
    <el-container>
      <el-main class="vh-100 d-flex justify-content-center">
        <el-row :gutter="24" :justify="'center'" class="w-100">
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6" class="m-0 p-0 align-content-center align-items-center">
            <p class="w-100 mb-4 noselect" style="font-size: 2.5rem">Ваши устройства</p>
            <div v-for="(device, deviceIndex) in devices" :key="'devnd7jrjs_nsd_' + deviceIndex">
              <div class="d-flex justify-content-between align-items-center mb-3"
                   @click="this.show[device.id] = !this.show[device.id]" style="cursor: pointer">
                <div style="font-size: 1.4rem" class="noselect">{{ device.name }}</div>
                <el-icon style="font-size: 1.4rem" v-if="this.show[device.id] === false">
                  <ArrowRight/>
                </el-icon>
                <el-icon style="font-size: 1.4rem" v-else>
                  <ArrowDown/>
                </el-icon>
              </div>
              <div
                  v-show="show[device.id]"
                  class="mb-3 p-2"
                  style="background-color: #363636; color: white; border-radius: 10px"
              >
                <div class="d-flex justify-content-between align-items-center mb-2 mt-2">
                  <p class="m-0 p-0">ID:</p>
                  <p class="m-0 p-0" @click="showIDs = true" v-if="showIDs === false">
                    <el-icon>
                      <View/>
                    </el-icon>
                  </p>
                  <p class="m-0 p-0" v-if="showIDs === true">{{ device.id }}</p>
                </div>
                <div class="d-flex justify-content-between align-items-center mb-2 mt-2">
                  <p class="m-0 p-0">Имя:</p>
                  <div class="d-flex justify-content-end align-items-center">
                    <p class="m-0 p-0">{{ device.name }}</p>
                    <ChangeDeviceName :device="device"/>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center mb-2 mt-2">
                  <p class="m-0 p-0">Местоположение:</p>
                  <div class="d-flex justify-content-end align-items-center">
                    <p class="m-0 p-0">{{ device.city === 'not_defined' ? 'Авто' : device.city }}</p>
                    <ChangeDeviceLocation :device="device"/>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center mb-3 mt-2">
                  <p class="m-0 p-0">Последний раз онлайн:</p>
                  <p class="m-0 p-0">{{ device.system.last_seen }}</p>
                </div>
                <hr style="color: white">
                <div v-for="(widget, widgetIndex) in device.widgets" :key="'deviceWidgeek4df_0_sdf' + widgetIndex">
                  <div class="d-flex justify-content-between mb-2 noselect" v-if="widget.type === 'shared'">
                    <p class="m-0 p-0">{{ widget.name_ru }}:</p>
                    <p class="m-0 p-0" v-if="widget.visible === true"
                       @click="widget.visible = !widget.visible; changeWidgetVisibility(device, widget)"
                       style="cursor: pointer">
                      <el-icon>
                        <View/>
                      </el-icon>
                    </p>
                    <p class="m-0 p-0" v-else
                       @click="widget.visible = !widget.visible; changeWidgetVisibility(device, widget)"
                       style="cursor: pointer">
                      <el-icon>
                        <Hide/>
                      </el-icon>
                    </p>
                  </div>
                </div>
                <div v-for="(widget, widgetIndex) in device.widgets" :key="'sdf22errrr32855ss' + widgetIndex">
                  <div class="d-flex justify-content-between mb-2 noselect" v-if="widget.type === 'personal'">
                    <p class="m-0 p-0">{{ widget.name_ru }}:</p>
                    <div class="d-flex">
                      <div v-if="widget.name === 'notes'">
                        <Notes :device="device"/>
                      </div>
                      <div v-if="widget.name === 'sprut_hub'">
                        <SprutHub :device="device"/>
                      </div>
                      <div v-if="widget.name === 'gallery'">
                        <Gallery :device="device"/>
                      </div>
                      <div v-if="widget.name === 'iframe'">
                        <IFrame :device="device"/>
                      </div>
                      <div v-if="widget.name === 'horoscopes'">
                        <HoroscopeWidget :device="device"/>
                      </div>
                      <p class="m-0 p-0" v-if="widget.visible === true"
                         @click="widget.visible = !widget.visible; changeWidgetVisibility(device, widget)"
                         style="cursor: pointer">
                        <el-icon>
                          <View/>
                        </el-icon>
                      </p>
                      <p class="m-0 p-0" v-else
                         @click="widget.visible = !widget.visible; changeWidgetVisibility(device, widget)"
                         style="cursor: pointer">
                        <el-icon>
                          <Hide/>
                        </el-icon>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>


<script>
import {mapActions, mapGetters} from 'vuex'
import {ElMessage} from 'element-plus'
import axios from 'axios'
import utils from '@/utils'
import ChangeDeviceName from '@/components/MainView/DeviceName.vue'
import ChangeDeviceLocation from '@/components/MainView/City.vue'
import Notes from '@/components/MainView/Notes.vue'
import SprutHub from '@/components/MainView/SprutHub.vue'
import Gallery from '@/components/MainView/Gallery.vue'
import IFrame from '@/components/MainView/IFrame.vue'
import HoroscopeWidget from '@/components/MainView/Horoscopes.vue'

export default {
  name: 'MainView',
  components: {
    ChangeDeviceLocation,
    HoroscopeWidget,
    ChangeDeviceName,
    Notes,
    SprutHub,
    Gallery,
    IFrame,
  },
  props: {},
  computed: {
    ...mapGetters({
      devices: "devices/getDevices",
    })
  },
  watch: {
    devices() {
      if (this.devices.length > 0) {
        for (let device of this.devices) {
          this.show[device.id] = false
        }
      }
    }
  },
  data() {
    return {
      showIDs: false,
      show: {}
    }
  },
  methods: {
    ...mapActions(['devices/fetchDevices']),
    changeWidgetVisibility(device, widget) {
      if (!widget.enabled) {
        ElMessage({
          message: 'К сожалению, этот виджет в настоящее время недоступен',
          type: 'error'
        })
        widget.visible = false
        return
      }
      let payload = {name: device.name}
      let widgets = []
      for (let widget of device.widgets) {
        widgets.push(widget)
      }
      payload['widgets'] = widgets
      axios({
        method: 'PATCH',
        // headers: {'Authorization': this.$cookies.get('email')},
        url: utils.API + `devices/${device.id}`,
        headers: {'X-Auth': utils.getEmail()},
        // withCredentials: true,
        data: payload
      }).then(() => {
        ElMessage({
          message: `Виджет "${widget.name_ru}" будет ${widget.visible === true ? 'восстановлен' : 'скрыт'} в течение ближайших 5 минут`,
          type: 'success'
        })
      }).catch(error => {
        ElMessage({
          message: error.response.data ? error.response.data.detail : error,
          type: 'error',
        })
      })
    },
  },
  mounted() {
    if (!this.$cookies.get('email')) {
      this.$router.push('/login')
      return
    }
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    this.$store.dispatch('devices/fetchDevices')
    // setTimeout(() => {
    //   for (let device of this.devices) {
    //     this.show[device.id] = false
    //   }
    // }, 500)
    const int = setInterval(() => {
      if (this.devices.length > 0) {
        for (let device of this.devices) {
          this.show[device.id] = false
        }
        clearInterval(int)
      }
    }, 850)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */
}
</style>
